import "./home.page.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  arePayeesAvailable,
  isAdminAccount,
  selectAvailablePayees,
  selectCurrentPayee,
  selectUserName,
  selectUserRole,
} from "../../redux/user/selectors";
import Select from "../../components/ui/select/Select";
import { ReactComponent as SaveIcon } from "./../../assets/icons/save.svg";
import { useCallback, useEffect, useState } from "react";
import { startAddNotification } from "../../redux/core/core";
import { INotificationType } from "../../components/ui/notifications/item/NotificationItem";
import {
  IUserRole,
  updateUserSelectedPayeeAccount,
} from "../../redux/user/user";
import { ICompany } from "../../redux/companies/companies";
import { useQueryCompanies } from "../../redux/hooks/useQueryCompanies";
import { useQueryPayees } from "../../redux/hooks/useQueryPayees";
import HomePageNoPayees from "./no-payees/homepage-no-payees";
import { Link } from "react-router-dom";
import { DropDownSearch } from "components/specific/drop-down-search/DropDownSearch";

export const session_request_name = "payee-accounts-requested";

function HomePage() {
  const dispatchStore = useAppDispatch();

  const isAccountAdmin = useAppSelector(isAdminAccount);

  const role = useAppSelector(selectUserRole);
  const name = useAppSelector(selectUserName);
  const currentPayee = useAppSelector(selectCurrentPayee);
  const arePayeesAvailableState = useAppSelector(arePayeesAvailable);
  const availablePayees = useAppSelector(selectAvailablePayees);

  const [company, setSelectedCompany] = useState<ICompany | undefined>(
    undefined
  );
  const [currentSelectedPayeeAccount, setCurrentSelectedPayeeAccount] =
    useState("");

  const {
    data: companiesData,
    isLoading: areCompaniesLoading,
    error: companiesError,
  } = useQueryCompanies(role !== undefined && role === IUserRole.admin);

  const {
    data: payeesData,
    isLoading: arePayeesLoading,
    error: payeesError,
  } = useQueryPayees(
    company ? company.id : 0,
    company !== undefined && role === IUserRole.admin
  );

  if (companiesError || payeesError) {
    dispatchStore(
      startAddNotification({
        title: "Error",
        description: "Unknown api error occurred",
        type: INotificationType.ERROR,
      })
    );
  }

  const companiesPlaceholder = useCallback(() => {
    if (areCompaniesLoading) {
      return "Loading companies";
    }

    if (!companiesData || companiesError) {
      return "No companies available";
    }

    return "Select Company";
  }, [areCompaniesLoading, companiesData, companiesError]);

  const payeesPlaceholder = useCallback(() => {
    if (role === IUserRole.admin && !company) {
      return "No company selected";
    }

    if (role === IUserRole.user && availablePayees) {
      return "Select Payee";
    }

    if (arePayeesLoading) {
      return "Loading payee(s)";
    }

    if (!payeesData || payeesError) {
      return "No payee(s) available";
    }

    return "Select Payee";
  }, [
    arePayeesLoading,
    availablePayees,
    company,
    payeesData,
    payeesError,
    role,
  ]);

  useEffect(() => {
    if (arePayeesAvailableState) {
      localStorage.removeItem(session_request_name);
    }
  }, [arePayeesAvailableState]);

  useEffect(() => {
    if (role !== IUserRole.admin && currentPayee && availablePayees) {
      const company = availablePayees?.find((item) => {
        return item.companyId === currentPayee.companyId;
      });

      setSelectedCompany(company || undefined);
    }
  }, [role, currentPayee, availablePayees]);

  if (role === IUserRole.user && !arePayeesAvailableState) {
    return <HomePageNoPayees />;
  }

  if (role === IUserRole.supervisor && !arePayeesAvailableState) {
    return <HomePageNoPayees />;
  }

  const handleClearSelection = () => {
    setSelectedCompany(undefined);
  };

  function exportFirstName() {
    if (name) {
      const trimmedName = name.trim();
      const firstName = trimmedName.split(" ")[0];
      return firstName;
    }
  }

  return (
    <div className="content">
      <div className="content__wrapper content__wrapper--homepage">
        <h1 className="content__title--homepage content__title content__entry-title text-wrap">
          Welcome back to the CMS Supplier Portal,{" "}
          <strong>{exportFirstName()}</strong>
        </h1>

        {/* <p>
          After selecting a payee account, the <strong>Invoice Upload</strong>{" "}
          and <strong>History</strong> tabs <br /> will be activated.
          <br />
          <br />
          Activate a <strong>Payee Account</strong> in order to proceed further:
        </p> */}

        <p>
          To access Invoice Upload and Payee History please select your required
          payee from the drop down list, then click Access Account.
        </p>

        <div className="homepage-selections">
          {role === IUserRole.admin && (
            <DropDownSearch
              isDisabled={areCompaniesLoading || !companiesData}
              customClassName={"searchBox searchBoxFont"}
              requestClick={(event) => {
                if (!companiesData) {
                  return;
                }

                const company = companiesData.data.data.find(
                  (item) => item.id.toString() === event.trim().toLowerCase()
                );

                setCurrentSelectedPayeeAccount("");

                if (!company) {
                  setSelectedCompany(undefined);

                  return;
                }

                setSelectedCompany(company);
              }}
              requestClearSelection={handleClearSelection}
              data={
                areCompaniesLoading || !companiesData
                  ? []
                  : companiesData.data.data.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }))
              }
              id={"role_select"}
            />
          )}

          <Select
            id={"payee_select"}
            isDisabled={
              (role === IUserRole.admin && (!company || arePayeesLoading)) ||
              (role === IUserRole.user &&
                (!arePayeesAvailableState ||
                  !availablePayees ||
                  !availablePayees.length))
            }
            customClassName={"gl-ref-select"}
            placeholder={payeesPlaceholder()}
            requestChange={setCurrentSelectedPayeeAccount}
            data={
              role === IUserRole.admin
                ? !payeesData
                  ? []
                  : payeesData.data.data.map((item) => ({
                      id: item.id.toString(),
                      content: item.name + " - " + item.payeeCurrency,
                    }))
                : !availablePayees
                ? []
                : availablePayees.map((item) => ({
                    id: item.id.toString(),
                    content: item.name + " - " + item.payeeCurrency,
                  }))
            }
          />

          <button
            type="button"
            className="payee__save btn btn--default btn--short"
            disabled={
              !currentSelectedPayeeAccount ||
              (currentSelectedPayeeAccount &&
                currentPayee &&
                currentSelectedPayeeAccount === currentPayee.id.toString()) ||
              (role === IUserRole.admin && (!company || arePayeesLoading)) ||
              (role === IUserRole.user &&
                (!arePayeesAvailableState ||
                  !availablePayees ||
                  !availablePayees.length ||
                  !currentSelectedPayeeAccount))
            }
            onClick={activatePayeeAccount}
          >
            Access Account
            <SaveIcon />
          </button>
        </div>

        {/* <div
          className={`quick-links ${
            currentPayee &&
            Object.keys(currentPayee) &&
            Object.keys(currentPayee).length > 0
              ? "quick-links--active"
              : ""
          }`}
          data-title="Quick Access"
        >
          <div className="quick-links__actions">
            <Link to="/invoice-upload" className={`quick-links__action`}>
              Invoice Upload
            </Link>

            <Link to="/history" className={`quick-links__action`}>
              History
            </Link>

            <Link to="/profile" className={`quick-links__action`}>
              Profile
            </Link>
          </div>

          {isAccountAdmin ? (
            <div className="quick-links__actions">
              <Link to="/companies" className={`quick-links__action`}>
                Manage Companies
              </Link>

              <Link to="/users" className={`quick-links__action`}>
                Manage Users
              </Link>

              <Link to="/bank-details" className={`quick-links__action`}>
                Bank Details
              </Link>
            </div>
          ) : null}
        </div> */}

        <div
          className={`quick-links ${
            currentPayee &&
            Object.keys(currentPayee) &&
            Object.keys(currentPayee).length > 0
              ? "quick-links--active"
              : ""
          }`}
          data-title="Quick Access"
        >
          <div className="quick-links__actions">
            <Link to="/invoice-upload" className={`quick-links__action`}>
              Invoice Upload
            </Link>

            <Link to="/history" className={`quick-links__action`}>
              History
            </Link>

            <Link to="/profile" className={`quick-links__action`}>
              Profile
            </Link>
          </div>
        </div>

        {isAccountAdmin ? (
          <div
            className={`quick-links ${
              currentPayee &&
              Object.keys(currentPayee) &&
              Object.keys(currentPayee).length > 0
                ? "quick-links--active"
                : ""
            }`}
            data-title="Admin Access"
          >
            <div className="quick-links__actions">
              <Link to="/companies" className={`quick-links__action`}>
                Manage Companies
              </Link>

              <Link to="/users" className={`quick-links__action`}>
                Manage Users
              </Link>

              <Link to="/bank-details" className={`quick-links__action`}>
                Bank Details
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );

  async function activatePayeeAccount() {
    dispatchStore(updateUserSelectedPayeeAccount(null));

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 450);
    });

    const selectedPayee =
      role === IUserRole.admin
        ? payeesData
          ? payeesData.data.data.find(
              (item) => item.id.toString() === currentSelectedPayeeAccount
            )
          : null
        : availablePayees
        ? availablePayees.find(
            (item) => item.id.toString() === currentSelectedPayeeAccount
          )
        : null;

    if (
      (role === IUserRole.admin && !company && !selectedPayee) ||
      !selectedPayee
    ) {
      return;
    }

    dispatchStore(
      updateUserSelectedPayeeAccount(
        {
          ...selectedPayee,
          companyId: company ? company.id : selectedPayee.companyId,
          companyName: company ? company.name : undefined,
          payeeCurrency: selectedPayee.payeeCurrency,
        } || null
      )
    );

    if (!selectedPayee) {
      return;
    }

    return dispatchStore(
      startAddNotification({
        title: "Success",
        description: `Successfully updated Payee Account to ${selectedPayee.name}`,
        type: INotificationType.SUCCESS,
      })
    );
  }
}

export default HomePage;
